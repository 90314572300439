<template>
   <div style="width: 100%;"  v-if="card.hideApproval === false"> 
  <div cols="12" sm="12" class="text-center col-sm-12 col-md-12 col-lg-12" style="padding:0;">
    <v-col>
      <div v-if="card.header.source != 'sailpoint' && card.header.source != 'nspta'">
      <v-btn outlined rounded text style="margin-right: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Approve'">
        <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
        <b>Approve</b>
      </v-btn>
      <v-btn  v-if="card.header.source != 'concur'" outlined rounded text style="margin-left: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Reject'">
        <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
        <b>Reject</b>
      </v-btn>   
      <v-btn v-if="card.header.source === 'concur'" outlined rounded text style="margin-left: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Reject'">
        <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
        <b>Send Back to Employee</b>
      </v-btn>
       </div>

       <div v-if="card.header.source == 'nspta'">
        <div v-if= "this.nsptaSession == null">
          <v-btn outlined rounded text style="margin-right: 5px; background-color:white; font-size: 13px" :href="nsptaLoginurl" v-on:click="nsptaPopupApprove(); card.reviewType='Approve'">
          <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
          <b>Approve</b>
          </v-btn>
          <v-btn  outlined rounded text style="margin-right: 5px; background-color: white; font-size: 13px" :href="nsptaLoginurl" v-on:click="nsptaPopupReject(); card.reviewType='Reject'">
          <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
          <b>Reject</b>        
          </v-btn>
          <v-btn outlined rounded text style="margin-top: 5px; background-color: white; font-size: 13px" :href="nsptaLoginurl" v-on:click="nsptaPopupclarification(); card.reviewType='clarification'">
          <b>Request for Clarification</b>
          </v-btn>
        </div>
        <div v-if= "this.nsptaSession != null">      
          <v-btn  outlined rounded text style="margin-right: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Approve'">
            <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
            <b>Approve</b>
          </v-btn>
          <v-btn v-if="card.header.source != 'concur'" outlined rounded text style="margin-left: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Reject'">
            <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
            <b>Reject</b>
          </v-btn>
          <v-btn v-if="card.header.source === 'nspta'" outlined rounded text style="margin-top: 5px; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='clarification'">
            <b>Request for Clarification</b>
          </v-btn>
        </div>
      </div>

      <div v-if="card.header.source === 'sailpoint'">
      <v-btn outlined rounded text style="margin-right: 0; background-color: white; font-size: 13px" @click="card.commentDialog=true; card.reviewType='Submit'">
        <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
        <b>Approve / Reject</b>
        <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
      </v-btn>
      </div>
    </v-col>
    <v-dialog :key="`${card.id}-${card.reviewType}`" v-model="card.commentDialog" max-width="700px">
    
      <v-card style="background-color: #E5E5E5; padding:10px" >
        <v-card-title v-if="card.header.source != 'concur' && card.header.source != 'sailpoint' && card.header.type != 'timeOff'  && card.header.type != 'timesheet' && card.header.source != 'replicon'" class="justify-center">{{ card.reviewType }} Request : {{ card.header.referenceId.replace(/[^-]*-/,"")}}?</v-card-title>
        <v-card-title v-if="card.header.source == 'sailpoint'" class="justify-center">{{ card.reviewType }} Request : {{ card.header.referenceId.replace(/[^-]*-/,"").slice(4,50)}}?</v-card-title>
        <v-card-title v-if="card.header.type === 'travel Requests' && card.reviewType == 'Approve'" class="justify-center">Approve Travel Request: {{ card.header.referenceId.replace(/[^-]*-/,"")}}?</v-card-title>
        <v-card-title v-if="card.header.type === 'travel Requests' && card.reviewType == 'Reject' " class="justify-center">Send Back Travel Request to Employee : {{ card.header.referenceId.replace(/[^-]*-/,"")}}?</v-card-title>
        <v-card-title v-if="card.header.type === 'expense Reports' && card.reviewType == 'Approve'" class="justify-center">Approve Employee Report : {{ card.header.title}}?</v-card-title>
        <v-card-title v-if="card.header.type === 'expense Reports' && card.reviewType == 'Reject' " class="justify-center">Send Back to Employee Report  : {{ card.header.title}}?</v-card-title>
        <v-card-title v-if="card.header.type == 'timeOff'" class="justify-center">{{ card.reviewType }} Request : <span style="font-size:17px">{{card.data.repliconDetails.timeOffType}}, {{ showDate(card.data.repliconDetails.leaveStartDate) }} - {{ showDate(card.data.repliconDetails.leaveEndDate) }}?</span></v-card-title>
        <v-card-title v-if="card.header.type == 'timesheet' && card.header.source == 'replicon'" class="justify-center">{{ card.reviewType }} Timesheet : <span style="font-size:17px"> {{ card.header.title }}? </span></v-card-title>

        <div :style="{'margin-left': '20px'}"  v-if="(card.header.type === 'workOrder' || card.header.type === 'timesheet' && card.header.source != 'replicon' || card.header.type === 'jobPosting') && card.reviewType === 'Reject'">
        <p><em>Please choose a reason for rejecting this request:</em></p>
          <v-radio-group v-model="card.rejectReason">
            <Rejectreasons :cardData="card.data.fieldglassDetails" :cardDatatime="card.data.timesheetDetails" :cardDatajobposting="card.data.jobpostingDetails" :headertype="card.header" :reasondata="reasondata" />
             </v-radio-group>            
        </div>
        <div class="multiapprove" v-if="card.header.source === 'sailpoint'">            
            <v-simple-table fixed-header outline style="margin-top:10px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Access Name</th>
                    <th class="text-left">Approve/ Reject</th>
                  </tr>
                </thead>
                <tbody v-for="access in card.data.sailpointDetails.accesses" :key="access.id">
                    <tr v-for="(accessname,index) in access.accessName" :key="index.id">
                      <td width=400px >{{accessname}}</td>
                      <td>                      
                  <v-radio-group v-model="multi[index]" row :mandatory="true">
                    <v-radio v-for="btnLabel in items" :key="btnLabel.id"              
                      :label="`${btnLabel}`"
                      :value="`${btnLabel}`"
                    ></v-radio>             
                  </v-radio-group> 
                  </td>
                  </tr></tbody>
              </template>
            </v-simple-table>
        </div>
        <v-form ref="sform">
          <v-textarea v-if= "card.reviewType=='Approve' " v-bind:class="'nspta_'+ card.header.referenceId" style=""  v-model="card.header.comment" placeholder="Please enter a comment about this request (optional)"></v-textarea>
          <v-textarea v-if= "card.reviewType=='Reject' || card.reviewType =='clarification'" class= "approve-textarea " style="" :rules="rules"  v-model="card.header.comment" required placeholder="Please enter a comment about this request"></v-textarea>
      
        <v-card-actions class="justify-center concur-btn" >
          <div v-if="card.header.source != 'concur'">
          <v-btn v-if="card.header.source != 'sailpoint' && card.reviewType !='clarification' " color="{ card.requestType == 'Approve' ? primary : danger }" :disabled="(card.header.type === 'workOrder' || card.header.type === 'timesheet' && card.header.source != 'replicon'|| card.header.type === 'jobPosting' ) && card.reviewType === 'Reject'  && !card.rejectReason " v-on:click='review()' >{{ card.reviewType }}</v-btn>
          <v-btn v-if="card.header.source === 'sailpoint'" color="{ card.requestType == 'Approve' ? primary : danger}" v-on:click='reviewAccesslevel()'>{{ card.reviewType }}</v-btn> 
          <v-btn v-if="card.header.source === 'nspta' && card.reviewType =='clarification' " color="{ card.requestType == 'Approve' ? primary : danger }" v-on:click='review(card.reviewType)'> Submit </v-btn>
          <v-btn v-on:click='cancel()'>Cancel</v-btn>
          </div> 
          <div v-if="card.header.source === 'concur'">  
           <v-btn v-if="card.reviewType === 'Approve'" color="{ card.requestType == 'Approve' ? primary : danger }" :disabled="(card.header.type === 'workOrder' ||  card.header.type === 'timesheet' || card.header.type === 'jobPosting' ) && card.reviewType === 'Reject' && !card.rejectReason" v-on:click='review()'>Approve</v-btn>
           <v-btn v-if="card.reviewType === 'Reject'" color="{ card.requestType == 'Approve' ? primary : danger }" :disabled="(card.header.type === 'workOrder' ||  card.header.type === 'timesheet' || card.header.type === 'jobPosting' ) && card.reviewType === 'Reject' && !card.rejectReason" v-on:click='review(card.reviewType)'>Submit</v-btn>
           <v-btn v-on:click='cancel()'>Cancel</v-btn>
          </div>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
  </div>
</template>

<script>
import Rejectreasons from '../viewComponents/rejectreason'
export default {
  props: ['card','reasondata'],
  components: {
    Rejectreasons,
  },
  data: () => ({
      items: ['approve', 'reject'],
      multi:[0, 0],
      header:'',
      data:null,
      isActive: false,
      rules: [],
      nsptaSession:'',
      nsptaLoginurl:'',
      
    }),
    async mounted(){
      this.nsptaLoginurl = process.env.VUE_APP_NSPTA_APP_URL;
      this.nsptaSession = sessionStorage.getItem("nsptatoken");    
      const nsptaReviewID = sessionStorage.getItem("nsptaReviewID");
      if(this.nsptaSession != null && nsptaReviewID == this.card.id){      
        if (sessionStorage.getItem("nsptaReview") == 'Approve'){
          this.nsptaPopupApprove();
        }
        else if(sessionStorage.getItem("nsptaReview") == 'Reject'){
          this.nsptaPopupReject();
        }
        else{
          this.nsptaPopupclarification();
        } 
       this.card.commentDialog = true;      
      }       
      var referrer = this.$router.history.current.fullPath;
      if (referrer == "/finance"){
        this.card.commentDialog = false; 
      }
    
    },
  methods: { 
    nsptaPopup(){
      const nsptaID = this.card.id;
      sessionStorage.setItem("nsptaReviewID", nsptaID);
      this.card.commentDialog = false;
    },
    nsptaPopupApprove(){
      const nsptaReviewtype = "Approve";      
      sessionStorage.setItem("nsptaReview", nsptaReviewtype);
      this.card.reviewType = sessionStorage.getItem("nsptaReview");
      this.nsptaPopup();
    },
    nsptaPopupReject(){
      const nsptaReviewtype = "Reject";
      sessionStorage.setItem("nsptaReview", nsptaReviewtype);
      this.card.reviewType = sessionStorage.getItem("nsptaReview");
      this.nsptaPopup();
    },
    nsptaPopupclarification(){
      const nsptaReviewtype = "clarification";
      sessionStorage.setItem("nsptaReview", nsptaReviewtype);
      this.card.reviewType = sessionStorage.getItem("nsptaReview");
      this.nsptaPopup();
    },
     showDate (time) {
        return (
          this.$store.state.dateFormat === "EUR"  
          ? new Intl.DateTimeFormat('en-GB').format(new Date(time)) 
          : new Intl.DateTimeFormat('en-US').format(new Date(time))
        )
      },
     
      async review(){
      if(!this.card.header.comment && this.card.reviewType=='Reject') {
       this.rules = [v => !!v || 'Please enter a comment',],   
       this.$refs.sform.validate(true)        
      }
      else if(!this.card.header.comment && this.card.reviewType ==='clarification') {
       this.rules = [v => !!v || 'Please enter a comment',],   
       this.$refs.sform.validate(true)        
      }
      else {
      const user = await this.$auth.getUser()
      const remove = await this.$store.dispatch('review', this.card, user.coreId)
      if (remove) {
        this.$emit('reviewed', this.card);
        window.location.reload();
      }
      }      
    },
    async reviewAccesslevel(){ 
      let success;
      this.card.commentDialog = false;
      this.card.pendingOverlay = true; 
      const reviewType = this.card.reviewType.toLowerCase();
      const cardName = this.card.header.source;
      if (cardName === "sailpoint"){
          var itemsDecision = {};
          const cardaccessName = this.card.data.sailpointDetails.accesses[0].accessName;
          const itemsDecisiontest = this.multi;
          for(var i=0; i< cardaccessName.length; i++){
            itemsDecision[cardaccessName[i]]= itemsDecisiontest[i];
          }
     
          const multiApproveId = this.card.header.referenceId;
      try {
        await this.$axios.post(`/requests/${multiApproveId}/approve`, {itemsDecision});
         this.card.pendingOverlay = false;
          if (reviewType === 'submit') {
            this.card.successOverlay = true;
          } else {
            this.card.rejectedOverlay = true;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          })
          this.card.approvalOverlay = true;
          if (reviewType === 'submit') {
            this.card.successOverlay = false;
          } else {
            this.card.rejectedOverlay = false;
          }
          success = true;
          this.$emit('reviewed', this.card);
       } catch (err){
          this.card.pendingOverlay = false;
          this.card.failedOverlay = true;
          await this.$axios.post(`/errors`, { referenceId: this.card.id, system: this.card.header.source, action: this.card.reviewType}).catch(() => console.log('error failed to create'));
          await new Promise(resolve => {
            setTimeout(resolve, 7000);
          })
          this.card.failedOverlay = false;
        }
        this.card.header.comment = '';
        this.multi= [0,0];        
        return success;
      }
    },
    cancel() {
      this.card.commentDialog = false; 
      this.card.header.comment="";
      this.card.rejectReason = "";
      this.multi = [0,0]; 
       this.rules = [];
    }
  }
}
</script>
<style>

.v-textarea textarea{
  background: #fff;
    border: 1px solid #ddd;
    height: 78px;
    border-radius: 10px;
    padding:5px 10px;
}

#selectAll label{
  font-weight: bold;
}
.multiapprove .v-input--selection-controls{
  margin-top: 0;
  padding-top: 0;
}
.multiapprove .v-data-table--fixed-header > .v-data-table__wrapper {
    overflow-y:hidden;
}
.multiapprove .v-input .v-label{
      text-transform: capitalize;
}
.static{
   display: none;
 }
 .static.active{
   display: block;
 }
 tr.static.active{
   display: table-row;
 }
 .theme--light.v-label{
   text-transform: capitalize;
   font-size: 15px;
 }
 .concur-btn .v-btn{
  margin:0 5px;
 }

</style>