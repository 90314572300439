<template>
  <div>
    <loading :active.sync="isLoading" 
      :is-full-page="fullPage">
    </loading>
   
    <Toolbar v-bind:title="'DXC IT'" @searchParam="changeSearch" v-bind:imgSrc="require('../assets/DXC_IT_tabs.png')"></Toolbar> 
    <v-row>
      <v-col sm="12">
        <v-container style="padding-top:0; padding-bottom:0">
        <v-row no-gutters>
          <v-col
          class=""
            cols="6"
            sm="6"
          >
              <h2>Pending Requests</h2>
          </v-col>
          <v-col
           cols="4"
            sm="1"
            lg="2"
            style="max-width: 125px; margin-left: 39%;"
          >
              <v-select
              style="max-width: 100px;"
              :items="sorters"
              label="Sort By"
              @input="sortCards"
            ></v-select>
          </v-col>
        </v-row>
        </v-container>
<v-container>
      <div :key="cardKey">
          
            <v-tabs v-model="tab">
            <v-tab v-for="tabname in tablist" :key="tabname.id" v-on:click="currentTab(tabname)">{{tabname}}</v-tab>
          
            <v-tabs-items v-model="tab">
             <v-tab-item  v-for="item in tablist" :key="item.tab">  
             <div class="text-xs-center" >
                <div class="delete-block">
                          <v-checkbox   style="display:inline-block" v-model="allSelected" @click="selectAll"                
                            :label="`Select All`"
                          ></v-checkbox>
                </div>
                <!-- bulk and delete block -->
                <v-card v-bind:class="{active: actionBtnall}" justify="center"  style="display: none;">
                  <Overlay :card="cardOverlay" />  
                  <div class="release-right" style="text-align:center">
                       <!-- <Bulkapproved  :card="card" :selectedCheck="selectCheckbox" />-->
                      <v-btn class="close-btn" v-on:click='cancel()'>
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                      <v-simple-table fixed-header outline class="bulkapprove">
                            <template>
                              <thead>
                                <tr>
                                  <th class="text-left">Requests Selected</th>
                                  <th class="text-left">
                                   <span v-if="item === 'VirtualSM'.toLowerCase()">SRPA Id</span>
                                   <span v-if="item != 'VirtualSM'.toLowerCase()"> Reference Id</span> 
                                  </th>
                                  <th class="text-left">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                <td>{{selectCheckbox.length}}</td>
                                <td>
                                <p>{{selectCheckbox.map(elem => elem).toString()}}</p>
                                </td>
                                <td>
                                <v-col>
                                  <div v-if="item === 'virtualsm'|| item === 'uam' || item === 'sailpoint' || item === 'virtualsm_lsz'" style="margin:0 0 10px;display: inline;" >   
                                    <v-btn outlined rounded text style="margin-right: 5px; backgroundColor: white; fontSize: 13px" @click="commentDialog=true;reviewType='Approve'">
                                  <v-icon v-bind:style="{padding: '5px', color: 'green', fontSize:'20px'}">mdi-thumb-up</v-icon>
                                  <b>Approve</b>
                                  </v-btn>
                                  <v-btn outlined rounded text style="margin-left: 5px; backgroundColor: white; fontSize: 13px" @click="commentDialog=true; reviewType='Reject'">
                                    <v-icon v-bind:style="{padding: '5px', color: 'red', fontSize:'20px'}">mdi-thumb-down</v-icon>
                                    <b>Reject</b>
                                  </v-btn>
                                  </div>
                                  <v-btn style="margin-left: 5px;fontSize: 12px;height: 30px;" color="{ requestType == 'Approve' ? primary : danger }" @click="commentDialogdelete=true;"> Already Actioned </v-btn>                               
                                </v-col>
                                <!-- Bulkapproval popup--> 
                                <v-dialog  v-model="commentDialog" max-width="700px">
                                  <v-card style="background-color: #E5E5E5; padding:10px" >
                                    <div v-if="item === 'sailpoint'.toLowerCase()">
                                      <v-card-title class="justify-center">{{ reviewType }} Request </v-card-title>
                                      <v-simple-table fixed-header outline style="margin:10px 0 !important; overflow: auto; max-height: 200px;">
                                            <template v-slot:default>
                                              <thead>
                                                <tr>
                                                  <th class="text-left">Ids</th>
                                                  <th class="text-left">Access Name</th>                                                
                                                </tr>
                                              </thead>                      
                                                <tbody>
                                                  <tr v-for="rowbulk in sailpointbulktest" :key="rowbulk.id">
                                                  <td>{{rowbulk.ids}}</td>
                                                  <td>{{rowbulk.AccessNames}}</td> 
                                                                                                
                                                  </tr>                     
                                              </tbody>
                                            </template>
                                          </v-simple-table>
                                    </div>
                                    <div v-if="item != 'sailpoint'.toLowerCase()">
                                    <v-card-title class="justify-center">{{ reviewType }} Request : {{selectCheckbox.map(elem => elem).toString()}}?</v-card-title>
                                  </div>
                                    <v-form ref="vsmform">
                                    <v-textarea  class= "approve-textarea" style="" :rules="rules"  v-model="comment" required placeholder="Please enter a comment about this request"></v-textarea>
                                    <v-card-actions class="justify-center concur-btn" >         
                                    <v-btn  color="{ requestType == 'Approve' ? primary : danger }"  v-on:click='bulkApproval()'>{{ reviewType }}</v-btn>
                                    <v-btn v-on:click='cancelVsm()'>Cancel</v-btn>
                                  </v-card-actions>
                                  </v-form>
                                  </v-card>
                                </v-dialog>
                                <!-- Bulkapproval popup end--> 

                               <!-- Delete popup-->
                                <v-dialog  v-model="commentDialogdelete" max-width="700px">
                                  <v-card style="background-color: #E5E5E5; padding:10px" >
                                    <v-card-title class="justify-center">Request : {{selectCheckbox.map(elem => elem).toString()}}?</v-card-title>
                                  <v-form ref="vsmform">
                                    <p class="delete-text">Are you sure you want to remove the request(s) from Approval Central work list?</p>
                                    <v-card-actions class="justify-center concur-btn" >         
                                    <v-btn  color="{ requestType == 'Approve' ? primary : danger }"  v-on:click='deleteReq()'>Yes</v-btn>
                                    <v-btn v-on:click='cancel()'>No</v-btn>
                                  </v-card-actions>
                                  </v-form>
                                  </v-card>
                                </v-dialog> 
                                <!-- Delete popup end-->
                              <!--    <v-btn outlined rounded text style="margin-left: 5px; backgroundColor: white; fontSize: 13px" v-on:click="deleteReq()"> Delete </v-btn>--></td>
                                </tr>
                              </tbody>
                            </template>
                      </v-simple-table>
                  </div>
                </v-card>
                  <!-- bulk and delete block end -->
             </div>       
                  <v-flex v-for="card in visibleCards" :key="card.id"  style="position: relative;">
                    <v-checkbox @click="select" class="selectbox"
                        v-model="selectCheckbox" :value="card.header.referenceId" :id="card.header.referenceId"
                      ></v-checkbox>
                    <div v-if="item === 'sailpoint'.toLowerCase()">
                      <SailpointRecord  style="padding: 0 0 0 60px;" v-if="card.header.source === 'sailpoint'" :card="card" @reviewed="removeCard"/>
                    </div>
                    <div v-if="item === 'uam'.toLowerCase()">
                      <UamRecord  style="padding: 0 0 0 60px;" v-if="card.header.source === 'uam'" :card="card" @reviewed="removeCard"/>
                      <v-dialog v-model="uamApprovalstatus" content-class="releaeblock" max-width="700px" persistent>
                        <v-card justify="center"  style="border-radius: 0;">
                        <div class="release-left">
                        <h4>Approval Results</h4>
                      </div>
                      <div class="release-right">                      
                        <v-simple-table fixed-header class="approval-table time-table">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th>Request ID</th>
                                <th>Approval Action</th>
                                <th>Was Action Successfully</th>
                                <th>Status Message</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="uamStatus in approvalStatus.approvalIdsWithStatus" :key="uamStatus.id" >
                                <td>{{uamStatus.id}}</td>
                                <td class="approval_action">
                                  <span v-if="reviewType === 'Approve'">Approved</span>
                                  <span v-if="reviewType != 'Approve'">Rejected</span>
                                </td>
                                <td>{{uamStatus.isSuccess}}</td>              
                                <td>{{uamStatus.message}}</td>                                
                              </tr>                       
                           
                            <tr v-for="uamStatusfalse in falseMessage" :key="uamStatusfalse.id">
                                <td>{{uamStatusfalse.id}}</td>
                                <td class="approval_action">
                                  <span v-if="reviewType === 'Approve'">Approved</span>
                                  <span v-if="reviewType != 'Approve'">Rejected</span>
                                </td>
                                <td>false</td>              
                                <td>{{uamStatusfalse.message}}</td>                                
                              </tr>
                            </tbody> 
                          </template>
                        </v-simple-table>
                        </div>
                        <v-btn class="close-btn"  v-on:click='ApprovalStatuscancel()'> <v-icon>mdi-window-close</v-icon></v-btn>
                      </v-card>
                      </v-dialog>  
                    </div>
                    <div v-if="item === 'APARS'.toLowerCase()">
                      <AparsRecord style="padding: 0 0 0 60px;" v-if="card.header.source === 'APARS'" :card="card"  @reviewed="removeCard"/>
                    </div>
                    <div class="vsm-list" v-if="item === 'VirtualSM'.toLowerCase()|| item === 'VirtualSM_LSZ'.toLowerCase()">                     
                      <VirtualSMRecord  style="padding: 0 0 0 60px;" v-if="card.header.source === 'VirtualSM' || card.header.source === 'VirtualSM_LSZ'" :card="card" @reviewed="removeCard"/>
                    </div>
                    <div v-if="item === 'DXCDD'.toLowerCase()">
                      <DxcDD  style="padding: 0 0 0 60px;" v-if="card.header.source === 'DXCDD'" :card="card" @reviewed="removeCard"/>
                    </div>
                    <div v-if="item === 'UPtime'.toLowerCase()">
                    <ServiceNow  style="padding: 0 0 0 60px;" v-if="card.header.source === 'UPtime'" :card="card" @reviewed="removeCard"/>
                    </div>
                    <div v-if="item === 'licenseManager'.toLowerCase()">
                    <LicenseManager  style="padding: 0 0 0 60px;" v-if="card.header.source === 'licenseManager'" :card="card" @reviewed="removeCard"/>
                    </div>
                  </v-flex> 
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
       <!-- <v-flex v-for="card in visibleCards" :key="card.id">
        <SailpointRecord  v-if="card.header.source === 'sailpoint'" :card="card" @reviewed="removeCard"/>
        <UamRecord  v-if="card.header.source === 'uam'" :card="card" @reviewed="removeCard"/>
        <AparsRecord  v-if="card.header.source === 'APARS'" :card="card" @reviewed="removeCard"/>
        <VirtualSMRecord  v-if="card.header.source === 'VirtualSM'" :card="card" @reviewed="removeCard"/>
        <DxcDD  v-if="card.header.source === 'DXCDD'" :card="card" @reviewed="removeCard"/>
        </v-flex> -->
        </div>
        <div class="text-center mt-5" v-bind:class="{active: actionBtnall}">
          <Paginations :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" ></Paginations>
        <ul class="showItems">
            <li>Show Items:
              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                <option :value= "5">5</option>
                <option :value="10"> 10 </option>               
                <option :value= "totalRecords">All</option>
              </select>
            </li>
      </ul> 
        <!--  <v-pagination
            v-model="page"
            :length="Math.ceil(tabCards.length/perPage)"
            :class="{ hide: seen }"
            :page="page"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>-->
        </div>     
      </v-container>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Toolbar from '../components/viewComponents/toolbar';
  import SailpointRecord from '../components/searchRecord/SailpointRecord'
  import UamRecord from '../components/searchRecord/UamRecord'
  import AparsRecord from '../components/searchRecord/AparsRecord'
  import VirtualSMRecord from '../components/searchRecord/VirtualSMRecord';
  import DxcDD from '../components/searchRecord/DxcDD';
  import ServiceNow from '../components/searchRecord/ServiceNow';
  import Overlay from '../components/viewComponents/Overlay';
  import LicenseManager from '../components/searchRecord/LicenseManager';
  import Paginations from '../components/viewComponents/Paginations';
   //import Bulkapproved from '../components/viewComponents/bulkApproved';
 
  export default {
    props: ['card', 'hideApprova', 'header'],
    components: {
      Loading,
      SailpointRecord,
      Toolbar,
      UamRecord,
      AparsRecord,
      VirtualSMRecord,
      DxcDD,
      Overlay,
      ServiceNow,
      LicenseManager,
      Paginations
      //Bulkapproved
    },
    watch: {
    },
    data: () => ({
      status: '',
      statusCode: null,
      searchInput: '',
      selectedIndex: null,
      cardOverlay: {successOverlay:false,deleteOverlay:false,approvalOverlay: false, rejectedOverlay: false, pendingOverlay: false, failedOverlay: false},
      isLoading: false,
      fullPage: true,
      //page: 1,
      //perPage: 5,
      cards: [],
      sorters:['newest', 'oldest'],
      sort: '',
      red: false,
      cardKey: 0,     
      cardDataData : [],
      tablist:[],
      tab:null,
      tabCards:[],
      tablistCard:[],
      seen: false,
      actionBtnall:false,
        selectCheckbox:[],
        allSelected:false,
        commentDialog:'',
        commentDialogdelete:'',
        reviewType: '',
        comment:'',
        rules: [],
        uamApprovalstatus:false,
        approvalStatus:'',
        falseMessage:[],
        sailpointAccessbulk:[],
        sailpointAccessID:[],
        sailpointbulktest : [],
        ids:'',
        AccessNames:'',
        page: 1,
      totalPages: 0,
      totalRecords: 0,
      recordsPerPage: 5,
      enterpageno: '',
      totalSource:[],
      currentSource:'',
      totalId:[],
      pagelimit: 0,
      userId:{},                   
      }),
    
    async mounted () {
      
     /* this.isLoading = true
      const request = await this.$axios.get(`/useraccess?where={"status":"pending"}`).then(response => {
        return response.data.map(card => card = {...card, ...this.cardOverlay, commentDialog: null, reviewType: ''});
      });
      if (this.cards.length !== this.$store.state.userAccessCount) {
        await this.$store.dispatch('fetchCounts')
      }
      this.isLoading = false;
      this.cards = request;
      this.tablistCard= this.cards;
      for (var j=0;j< this.cards.length; j++){
         var sourceName = this.cards[j].header.source;
         if (!this.tablist.includes(sourceName)){
            this.tablist.push(sourceName)
          }
         // if (sourceName === "sailpoint"){
            //console.log(sourceName);                    
          //var sailpointbulk = this.cards[j].data.sailpointDetails.accesses;
          //this.sailpointAccessbulk[j]=sailpointbulk;
          //var ids = this.cards[j].header.referenceId;
         // var AccessNames = this.cards[j].data.sailpointDetails.accesses[0].accessName;
          //this.sailpointAccessID[j]= { ids, AccessNames};  
         
        //}
      }
      //console.log(this.sailpointAccessID);
      
     
     
    this.currentTab(this.tablist[0]);
    //return {...this.cardOverlay, commentDialog: null, reviewType: ''}
      */
  },
    computed: {
      visibleCards () {
        //return this.changeSearch(this.searchInput);
       
        if(this.searchInput != ''){
          return this.loadListItem(this.searchInput);
        }else{
          return this.cards;
        }
       
      },
      
     /* sailpointbulkAccess: function () {        
        var accessBulk = [];
        this.sailpointbulktest.forEach(function (value, i) {
          value.forEach(function (ele) {
           accessBulk[i]= {accessname : ele['accessName'], subtype : ele['identityFullName']}             
                //console.log(ele['accessName'][i++])
                //console.log(accessBulk)  
                                     
          })          
        });
        return accessBulk
       
      }*/
    
    },
    async created () {
      this.loadListItem();
      const username = await this.$auth.getUser();
      this.userId = username.employeeId;
    }, 
    methods:{
      
       //Pagination
       async loadListItem (param = ' ') {
              this.isLoading = true;
              if(param != null){
                const request = await this.$axios.get(`/useraccess?where={"status":"pending","source":"${this.currentSource}"}&searchparam=${param}&limit=${this.recordsPerPage}&page=${this.page}`)
                .then(response => {          
                  //console.log('response', response)
                  this.isLoading = false;          
                  this.$store.state.visibleCards = response.data.data         
                  this.totalPages = Math.ceil(response.data.count / this.recordsPerPage) // Calculate total records
                  this.totalRecords = response.data.count;
                  this.totalSource = response.data.source ;
                  this.totalId = response.data.requestID;  
                  return response.data.data.map(card => card = {...card, employeeId:this.userId, ...this.cardOverlay,  commentDialog: null, reviewType: ''});
                })
                  this.cards = request;
                  this.tablistCard=request;
                  
              }
             else{              
              const request = await this.$axios.get(`/useraccess?where={"status":"pending","source":"${this.currentSource}"}&limit=${this.recordsPerPage}&page=${this.page}`)
              .then(response => {          
                //console.log('response', response)
                this.isLoading = false;          
                this.$store.state.visibleCards = response.data.data         
                this.totalPages = Math.ceil(response.data.count / this.recordsPerPage) // Calculate total records
                this.totalRecords = response.data.count;
                this.totalSource = response.data.source;
                this.totalId = response.data.requestID;                    
                return response.data.data.map(card => card = {...card, employeeId:this.userId, ...this.cardOverlay, commentDialog: null, reviewType: ''});
              })
              
                this.cards = request;
                this.tablistCard=request; 
               
              }
            //this.sortCards('newest');
            for (var j=0;j< this.totalSource.length; j++){
              var sourceName = this.totalSource[j];
              if (!this.tablist.includes(sourceName)){
                  this.tablist.push(sourceName)
                }
            }
            //this.currentTab(this.tablist[3]);
            this.pagelimit =  Math.ceil(this.totalRecords - this.recordsPerPage)
        },
          onPageChange (page) {
            this.page = page;
            this.loadListItem()           
          },
          onChangeRecordsPerPage () {
            this.loadListItem();           
          },

      //sailpoint AccessName 
     sailpointAccessname(){
      let idAccessObject = {};
       
      for (var j=0;j< this.cards.length; j++){
            var sourceName = this.cards[j].header.source;
            if (sourceName === "sailpoint"){
            var idss = this.cards[j].header.referenceId;
            var AccessNamess = this.cards[j].data.sailpointDetails.accesses[0].accessName;
            this.sailpointAccessID.push({ idss, AccessNamess});
            idAccessObject[idss] = AccessNamess;
            }
            
          } 
        
          if (sourceName === "sailpoint"){ 
     
      for (var b=0;b < this.selectCheckbox.length; b++){ 
        let selectedRequestId = this.selectCheckbox[b];
        let selectAccessNames = idAccessObject[this.selectCheckbox[b]]
       for (var i=0; i < this.sailpointAccessID.length; i++){
          var ids = this.sailpointAccessID[i].idss;
        if (this.selectCheckbox[b] == ids){    
          this.sailpointbulktest[b]= { ids: selectedRequestId ,AccessNames : selectAccessNames};     
          //this.sailpointbulktest[b]= { ids,AccessNames};
        }
      }
        }
       
          return this.sailpointbulktest; 
      }   
      },
      

       //Bulk Approved Request
       selectAll(){
         this.selectCheckbox = [];
         this.actionBtnall = true;
          if (this.allSelected) {
              //for (var i=0;i< this.cards.length; i++){          
              //deleteId[i] = this.cards[i].header.referenceId;
              //this.selectCheckbox = [...this.selectCheckbox, this.cards[i].header.referenceId]
              //} 
              this.selectCheckbox =  this.totalId;
          }
          
          if (this.selectCheckbox.length == 0){
            this.actionBtnall = false;
            this.selectCheckbox = [];
            this.allSelected = false;
            this.sailpointbulktest.pop();            
          }
         this.sailpointAccessname();
          
         
      
       },
       select(){
         this.allSelected = false;
         this.actionBtnall = true;
         
        if (this.selectCheckbox.length == 0){
            this.actionBtnall = false;
            this.allSelected = false;
            this.sailpointbulktest.pop();
          } 
        this.sailpointAccessname();
          
         
    },
     //Delete Request moved to Archive
      async deleteReq(){
        this.commentDialogdelete = false;
        this.cardOverlay.pendingOverlay = true;
        try {
          await this.$axios.post('requests/archive', this.selectCheckbox);
          this.cardOverlay.pendingOverlay = false;
          this.cardOverlay.deleteOverlay = true;
          await new Promise(resolve => {
              setTimeout(resolve, 2000);
            })
          this.cardOverlay.deleteOverlay = false;
          window.location.reload();
        }
        catch (err){
          this.cardOverlay.pendingOverlay = false;
          this.cardOverlay.failedOverlay = true;         
          this.$axios.post(`/errors`, { referenceId: this.selectCheckbox, system: "VirtualSM", action: this.reviewType}).catch(() => console.log('error failed to create'));
         
          await new Promise(resolve => {
            setTimeout(resolve, 7000);
          })
          this.cardOverlay.failedOverlay = false;
        }
          this.actionBtnall = false;
          this.selectCheckbox = [];
          this.allSelected = false; 
       },


 //Bulk Approval Request Approved/Reject 
        async bulkApproval(){
          if(!this.comment && this.reviewType=='Reject') {
          this.rules = [v => !!v || 'Please enter a comment',],   
          this.$refs[`vsmform`][0].validate(true)        
          }
          
          let success;
          this.commentDialog = false;
          this.cardOverlay.pendingOverlay = true;
          const reviewType = this.reviewType.toLowerCase();
          const comment = this.comment ? this.comment : '';
          var approverIds = {'ids':this.selectCheckbox};      
          const bulkId = this.selectCheckbox[0];
          var uamAppstatus;
          var itemsDecision = {}; 
          try {
            for (var i=0;i< this.cards.length; i++){
            var sourceName = this.cards[i].header.source;
          } 
            if (sourceName === "sailpoint"){
              itemsDecision.ids = this.selectCheckbox;
              var sailpointbulkAccess = {};  
              sailpointbulkAccess = this.sailpointAccessname();
             
              for (var q=0;q < sailpointbulkAccess.length; q++){  
                itemsDecision[sailpointbulkAccess[q].ids] = {}
                sailpointbulkAccess[q].AccessNames.forEach(access => {
                  itemsDecision[sailpointbulkAccess[q].ids][access] = reviewType
                })
              }
             //var payloadData = { 'id': "", comment, itemsDecision}
             //console.log('paylod', payloadData)
              uamAppstatus = await this.$axios.post(`requests/${bulkId}/${reviewType}`, { 'id': "", comment, itemsDecision});
            }
            else{             
             uamAppstatus = await this.$axios.post(`requests/${bulkId}/${reviewType}`, { 'id': "", comment, 'itemsDecision': approverIds}); 
            }
            this.approvalStatus = uamAppstatus.data
            this.cardOverlay.pendingOverlay =false;
           
          if (reviewType === 'approve') {
            this.cardOverlay.approvalOverlay = true;
          } else {
            this.cardOverlay.rejectedOverlay = true;
          }
          await new Promise(resolve => {
            setTimeout(resolve, 2000);
          })
          this.cardOverlay.approvalOverlay = true;
          if (reviewType === 'approve') {
            this.cardOverlay.approvalOverlay = false;
          } else {
            this.cardOverlay.rejectedOverlay = false;
          }           
          if (sourceName === "uam"){
            this.uamApprovalstatus = true;
          }else{
            window.location.reload();
          }
          success = true;
        }catch (err){
          this.cardOverlay.pendingOverlay = false;
          this.cardOverlay.failedOverlay = true;
          this.$axios.post(`/errors`, { referenceId: this.selectCheckbox, system: "VirtualSM", action: this.reviewType}).catch(() => console.log('error failed to create'));        
          await new Promise(resolve => {
            setTimeout(resolve, 5000);
          })          
         for (var j=0;j<this.selectCheckbox.length;j++){  
          if(this.selectCheckbox[j] != undefined){          
            var idselectCheckbox = this.selectCheckbox[j];
            this.falseMessage[j] = {'id':idselectCheckbox,'message': err.message}  
          }         
          }      
          this.cardOverlay.failedOverlay = false;                  
          this.uamApprovalstatus = true; 
        }
        this.comment = '';
        this.actionBtnall = false;
        this.selectCheckbox = [];
        this.allSelected = false;        
        return success;       
        },
        cancelVsm(){
          this.commentDialog = false;
          this.comment="";
          this.rules = [];
          this.sailpointbulktest.pop();
 
        },
      cancel() {
        this.actionBtnall = false;
        this.selectCheckbox = [];
        this.allSelected = false;
        this.commentDialogdelete = false;
        this.sailpointbulktest.pop();
      },
      ApprovalStatuscancel(){
        this.uamApprovalstatus = false;
        window.location.reload();
      },
     
       
      removeCard (cardData) {
        this.cards= this.cards.filter(_ => cardData.id != _.id )    
        return this.cards
      },
      sortCards (event) {
        this.cards.sort((a,b) => {
            if (a.header.availableForApprovalAt > b.header.availableForApprovalAt) {
              return event === 'newest' ? -1 : 1
            } else if (a.header.availableForApprovalAt < b.header.availableForApprovalAt) {
              return event === 'newest' ? 1 : -1
            } else {
              return 0;
            }
          });
      },
     
      searchClear(params){
       console.log("input clear")
 
          if(params === '' || params === undefined || params != ''){
            this.searchInput = '';
            this.seen = false;
            console.log(" clear")
            return this.cards;
          }
        },

      changeSearch (params) {
        this.searchClear();
        if (params) {
          params = params.toLowerCase();          
        }
        this.loadListItem(params);
       /* this.searchClear();
        if (params) {
          this.searchInput = params.toLowerCase();
        }
        if(params === '' || params === undefined){
          this.searchInput = '';
          return this.cards;
        }
        if(this.searchInput != ''){
          this.seen = true;
          return this.cards.filter(card => {
            let cardArr = Object.values(card.header).map(value => {      
              if (typeof(value) === 'string') { 
                return value 
                  } else if (!value) {
                    return
                  } else {
                    return Object.values(value)
                  }
              })
            cardArr = cardArr.flat().filter(str => {
              return typeof str === 'string';
            })
            const matches = cardArr.filter(string => new RegExp(this.searchInput).test(string.toLowerCase()) && this.searchInput != '')
            if (matches.length > 0) {
              this.page = 1;
              return card;
            }
          })
        }
        return this.cards; 
        */
      },

      currentTab(source,params){
        this.actionBtnall = false;
        this.selectCheckbox = [];
        this.allSelected = false; 
        this.tabCards = this.tablistCard.filter(_ => _.header.source === source)
        this.cards = this.tabCards;
        this.page = 1;
        this.currentSource  = source;  
        this.changeSearch(params);
        this.$refs.Toolbar.clearMessage();
        //this.$emit("searchParam", this.searchInput.trim());
        //this.loadListItem(); 
      },   


    }
  }
</script>
<style>
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
    margin-left: 15.5px;
    margin-right: 40px;
    max-width: 100%;
}
.theme--light.v-btn.v-btn--outlined{
    border-width: 2px;
    border-color: black;
}
.v-application .mt-5{
  margin-bottom: 20px !important;
  margin-top:0 !important;
}
.v-text-field__details{
  min-height: inherit !important;
}
.v-input.v-text-field {
    padding-top: 0; 
    margin-top: 0; 
}
.v-text-field__details .v-messages{
min-height: inherit !important;
}
.v-tabs-bar{
    border-bottom: 1px solid #ccc !important;
    margin: 0 0 10px !important;
    height: 35px !important;
}
.v-tab {
    font-size: 13px;
    color: #444 !important;
    background: #ddd;
    border-radius: 5px 5px 0 0;
    margin: 0 0 0 3px;
    font-weight: bold;
    text-transform: uppercase;
}
.v-tab--active{
    font-size: 14px;
    color: #fff !important;
    background-color: #5f249f;
}
.invisible, .v-tab:before, .v-tabs-slider-wrapper{
  display:none;
}
.v-application .v-card .release-right {
  padding: 10px 10px;
}
.v-application .v-card .release-right p.delete-text{
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
}
.delete-block{
  padding: 5px 0 0px;
  margin: 0px 0 15px;
  border-bottom: 1px solid #222;
}
.delete-block .theme--light.v-chip{    
    vertical-align: top;
    margin: 15px 10px 0;
    display: inline-block !important;
}

.v-input.selectbox{
  max-width: 30px;
  margin: 0;
  vertical-align: top;
   display: inline-block;
   position: absolute;
   top: 65px;
    left: 1%;
    z-index: 1;
    bottom: 50%;
    height: 30px; 
}
.v-input.selectbox.timeOff {
  top: 28%;
}
.v-application .del-block {
  margin: 0;
  max-width: 97%;
  display: inline-block; 
  vertical-align: top; 
}
body .close-btn{
    background: #ddd !important;
    border: 0;
    border-radius: 50px;
    height: 30px!important;
    min-width: 30px !important;
    position: absolute;
    right: -10px;
    top: -10px;
    transition: 1s ease-in-out;
    font-size: 18px;
    z-index: 100;
    padding: 5px!important;
  }
body .close-btn:hover{
        transform: rotate(180deg);
    }
    body .close-btn .v-icon{
      color: #000;
      font-size: 18px;
    }
    .v-card.active{
      display: block !important;
      position: fixed;
    bottom: 10px;
    z-index: 10;
    width: 92%;
    background: #ccc;
    }

 .v-application .mt-5.active{
  margin-bottom: 90px !important;
 } 
 .v-application .theme--light.v-data-table.v-data-table--fixed-header.bulkapprove thead th{
  height: 34px;
  font-size: 11px;
    background: #5F249F;
 }
 p.delete-text{
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  text-align: center;
}
.v-application .grey.lighten-5{
  margin-left: -54px;
}
.v-application .v-card p.delete-text{
  font-size: 15px;
}
.showItems {
  display: flex;
  list-style: none;
  place-content: flex-end;
  margin-top: -52px;
}
.showItems select{
  text-decoration: none;
  color: #2c3e50;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 30px;
  width: 60px;
  margin: 0.3rem 10px;
  /* display: block; */
  line-height: 32px;
  appearance: button;
  -moz-appearance: button;
  -webkit-appearance: button;
}
</style>